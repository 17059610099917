import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import { AutoComplete } from "react-bmapgl";

function valuetext(value) {
  return `${value}分钟`;
}

export default function TemporaryDrawer(props) {
  const [state, setState] = useState({
    left: false,
  });
  const [city, setCity] = useState("北京");
  const [address, setAddress] = useState("");
  const [timeLimit, setTimeLimit] = useState(
    parseInt(process.env.REACT_APP_TIME_LIMIT_DEFAULT)
  );

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setTimeLimit(timeLimit);
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: "auto",
        display: "grid",
        flexWrap: "wrap",
        flexDirection: "column",
        m: 1,
        zIndex: -1,
        bgcolor: "background.paper",
        alignItems: "center",
      }}
      role="presentation"
    >
      <div
        sx={{
          p: 1,
          m: 1,
          zIndex: -1,
        }}
      >
        <TextField
          id="ac"
          label="通勤目的地址"
          sx={{
            mt: 1,
            mb: 1,
            width: 300,
          }}
          variant="outlined"
        />
        <AutoComplete
          input="ac"
          onConfirm={(e) => {
            setCity(e.item.value.city);
            setAddress(
              e.item.value.city + e.item.value.district + e.item.value.business
            );
          }}
        />
        <Box
          sx={{
            mt: 1,
            mb: 1,
          }}
        >
          <label>通勤时间限制（分钟）：</label>
        </Box>
        <Slider
          aria-label="Time limit"
          value={timeLimit}
          getAriaValueText={valuetext}
          valueLabelDisplay="auto"
          step={parseInt(process.env.REACT_APP_TIME_LIMIT_STEP)}
          marks
          min={parseInt(process.env.REACT_APP_TIME_LIMIT_MIN)}
          max={parseInt(process.env.REACT_APP_TIME_LIMIT_MAX)}
          onChange={(props) => {
            setTimeLimit(props.target.value);
          }}
        />
        <Button
          sx={{
            p: 1,
          }}
          variant="contained"
          textalign="center"
          onClick={() => {
            props.onClick(city, address, timeLimit);
          }}
        >
          搜索
        </Button>
      </div>
      <div>
        <div className="mt24 mb6">
          <h2 className="txt-bold txt-m block">提示：</h2>
          <p className="txt-m w300 color-gray">
            请输入您的通勤目的地（如工作单位、学校）地址，还有您希望的单程通勤耗时。
          </p>
          <p className="txt-m w300 color-gray">
            我们将在地图上为您渲染工作日早八点的通勤通达区域。
          </p>
          <h2 className="txt-bold txt-m block">目前支持城市：</h2>
          <p className="txt-m w300 color-gray">
            北京、上海、广州、深圳、天津、南京、杭州、武汉、成都、重庆、青岛、苏州、无锡、常州、佛山、东莞、惠州。
          </p>
        </div>
      </div>
    </Box>
  );

  return (
    <div>
      <React.Fragment key="left">
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={toggleDrawer("left", true)}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          anchor="left"
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
          sx={{ zIndex: -1 }}
        >
          {list("left")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
