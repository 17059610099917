import React from "react";
import "../App.css";
import { Map, NavigationControl, ZoomControl } from "react-bmapgl";

function BaiduMap(props) {
  return (
    <Map
      ref={(ref) => {
        if (ref) {
          props.setMap(ref.map);
        }
      }}
      className="map-container"
      style={{ height: "100%" }}
      center={{
        lng: props.lng,
        lat: props.lat,
      }}
      zoom={props.zoom}
      enableScrollWheelZoom="true"
      enableDoubleClickZoom="true"
    >
      <NavigationControl />
      <ZoomControl />
    </Map>
  );
}

export default BaiduMap;
