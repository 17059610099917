import Pbf from "pbf"; // eslint-disable-line import/no-webpack-loader-syntax
import geobuf from "geobuf"; // eslint-disable-line import/no-webpack-loader-syntax
import { wgs84togcj02, gcj02tobd09 } from "coordtransform";

function wgs84tobd09(wgs84) {
  var gcj02 = wgs84togcj02(wgs84[0], wgs84[1]);
  return gcj02tobd09(gcj02[0], gcj02[1]);
}

function _base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

let renderGeoJSON = function (retJSON, setLegends) {
  let geoJSON = retJSON["isocontour"];
  let sorted = Object.keys(geoJSON);
  let polygons = [];
  let newLegends = {};
  sorted.sort().reverse();
  for (let timeCost of sorted) {
    for (let idx in geoJSON[timeCost]) {
      var decoded = geobuf.decode(
        new Pbf(_base64ToArrayBuffer(geoJSON[timeCost][idx]))
      );
      newLegends[timeCost] = decoded["properties"]["fill"];
      let polygon = {
        geometry: decoded["geometry"]["coordinates"].map((lineString) =>
          lineString
            .map((coord) => wgs84tobd09([coord[0], coord[1]]).join(","))
            .join(";")
        ),
        properties: decoded["properties"],
      };
      polygons.push(polygon);
    }
  }
  setLegends(newLegends);
  return polygons;
};

export default renderGeoJSON;
