let fetchJSON = function fetchJSON(url) {
  return new Promise((resolve, reject) => {
    let RESTrequest = url;
    fetch(RESTrequest).then(function (response) {
      resolve(response.json());
    });
  });
};

export default fetchJSON;
