import React from "react";

const Legend = (props) => {
  const renderLegendKeys = (legend, i) => {
    return (
      <div key={i} className="txt-s">
        <span
          className="mr6 round-full w12 h12 inline-block align-middle"
          style={{ backgroundColor: legend[1] }}
        />
        <span>{`${legend[0] / 60}分钟`}</span>
      </div>
    );
  };

  if (!!props.legends) {
    return (
      <>
        <div className="bg-white absolute bottom right mr12 mb24 py12 px12 shadow-darken10 round z1 wmax180">
          <div className="mb6">
            <h2 className="txt-bold txt-s block">通勤等时间线</h2>
            <p className="txt-s color-gray">在以下时间内，经由城市公共交通可抵达的区域</p>
          </div>
          {Object.entries(props.legends).map(renderLegendKeys)}
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default Legend;
