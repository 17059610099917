import React, { useState } from "react";
import "./App.css";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import TemporaryDrawer from "./components/TemporaryDrawer";
import Legend from "./components/Legend";
import BaiduMap from "./components/BaiduMap";
import Typography from "@mui/material/Typography";
import renderGeoJSON from "./commons/RenderGeoJSON";
import fetchJSON from "./commons/FetchJSON";
import { bd09togcj02, gcj02towgs84 } from "coordtransform";

function bd09towgs84(bd09) {
  var gcj02 = bd09togcj02(bd09[0], bd09[1]);
  return gcj02towgs84(gcj02[0], gcj02[1]);
}

const grab_region = {
  "北京市": "beijing",
  "上海市": "shanghai",
  "苏州市": "shanghai",
  "无锡市": "shanghai",
  "常州市": "shanghai",
  "广州市": "guangzhou",
  "深圳市": "guangzhou",
  "东莞市": "guangzhou",
  "佛山市": "guangzhou",
  "惠州市": "guangzhou",
  "南京市": "nanjing",
  "天津市": "tianjin",
  "武汉市": "wuhan",
  "杭州市": "hangzhou",
  "成都市": "chengdu",
  "重庆市": "chongqing",
  "青岛市": "qingdao",
}

function App() {
  const [map, setMap] = useState(null);
  const [layers, setLayers] = useState([]);
  const [legends, setLegends] = useState(null);
  let clearLayers = (map) => {
    for (let layer of layers) {
      map.removeOverlay(layer);
    }
    setLayers([]);
  };

  let addLayers = (map, newLayers) => {
    for (let layer of newLayers) {
      map.addOverlay(layer);
    }
    setLayers(layers.concat(newLayers));
  };

  let onButtonClick = (city, address, limit) => {
    var myGeo = new BMapGL.Geocoder();
    // 将地址解析结果显示在地图上，并调整地图视野
    myGeo.getPoint(address, function (point) {
      if (point) {
        var location = bd09towgs84([point["lng"], point["lat"]]);
        fetchJSON(
          `${
            process.env.REACT_APP_BRUSSELS_HOST
          }/isocontour/?` + new URLSearchParams({
            region: grab_region[city],
            lat: location[1],
            lon: location[0],
            min: parseInt(process.env.REACT_APP_TIME_LIMIT_MIN) * 60,
            max: limit * 60,
            step: parseInt(process.env.REACT_APP_TIME_LIMIT_STEP) * 60,
          })
        ).then((geoJSON) => {
          clearLayers(map);
          var polygons = renderGeoJSON(geoJSON, setLegends);
          var holes = [];
          for (let polygon of polygons) {
            var hole = new BMapGL.Polygon(polygon.geometry, {
              fillColor: polygon.properties["fill"],
              fillOpacity: polygon.properties["fill-opacity"],
              strokeColor: polygon.properties["stroke"],
              strokeWeight: polygon.properties["stroke-width"],
              strokeOpacity: polygon.properties["stroke-opacity"],
            });
            holes.push(hole);
          }
          var destPoint = new BMapGL.Marker(point);
          holes.push(destPoint);
          addLayers(map, holes);
          map.flyTo(point, 12);
        });
      } else {
        alert("您选择的地址没有解析到结果！");
      }
    });
  };
  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" sx={{ zIndex: -2 }}>
          <Toolbar>
            <TemporaryDrawer onClick={onButtonClick} />
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              通勤等时间线搜索
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <BaiduMap
        map={map}
        setMap={setMap}
        lng={process.env.REACT_APP_ORIGIN_LONGITUDE}
        lat={process.env.REACT_APP_ORIGIN_LATITUDE}
        zoom={process.env.REACT_APP_ORIGIN_ZOOM}
      />
      <Legend legends={legends} />
    </div>
  );
}

export default App;
